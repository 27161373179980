import * as React from "react"
import { Helmet } from "react-helmet";

import LayoutLuxuryResale from "../components/LayoutLuxuryResale"
import LuxuryResalePicture1 from "../components/LuxuryResalePage/LuxuryResalePicture1"
import LuxuryResalePicture2 from "../components/LuxuryResalePage/LuxuryResalePicture2"
import LuxuryResalePicture3 from "../components/LuxuryResalePage/LuxuryResalePicture3"
import LuxuryResalePicture4 from "../components/LuxuryResalePage/LuxuryResalePicture4"
import LuxuryResaleTextIntro from "../components/LuxuryResalePage/LuxuryResaleTextIntro"
import LuxuryResaleText2 from "../components/LuxuryResalePage/LuxuryResaleText2"
import LuxuryResaleText1 from "../components/LuxuryResalePage/LuxuryResaleText1"
import LuxuryResaleBrands from "../components/LuxuryResalePage/LuxuryResaleBrands"
import LuxuryResaleBrandsTitle from "../components/LuxuryResalePage/LuxuryResaleBrandsTitle"
import LuxuryResaleHalfHalfTitle from "../components/LuxuryResalePage/LuxuryResaleHalfHalfTitle"
import LuxuryResaleBusinessOpportunity from "../components/LuxuryResalePage/LuxuryResaleBusinessOpportunity"
import LuxuryResaleDownloadScrollButton from "../components/LuxuryResalePage/LuxuryResaleDownloadScrollButton"
// import LuxuryResaleHalfHalf1 from "../components/LuxuryResalePage/LuxuryResaleHalfHalf1"
// import LuxuryResaleHalfHalf2 from "../components/LuxuryResalePage/LuxuryResaleHalfHalf2"
import LuxuryResaleHalfHalf from "../components/LuxuryResalePage/LuxuryResaleHalfHalf"
import LuxuryResaleNewsletter from "../components/LuxuryResalePage/LuxuryResaleNewsletter"
// import LuxuryResaleResaleRentalBPBar from "../components/LuxuryResalePage/LuxuryResaleResaleRentalBPBar"


function LuxuryResalePage({ data }) {
  return (
    <LayoutLuxuryResale>
      <Helmet>
        <meta property="og:title" content="The Luxury Resale Report"/>
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3QFUklFCYxCfLHRDt1BSAP/857d8c9ae528b4f34bf5a30da1e5427d/Asset_1_2x.png" />
        <meta property="og:description" content="The Luxury Resale Report – Gain New Customers - Enhance Brand - Diversify Revenue" />
        {/* <meta property="og:url" content="https://thefreshkid.com/what-is-luxury-circulareconomy/" /> */}
      </Helmet>
      <LuxuryResaleTextIntro/>
      <LuxuryResalePicture1/>
      <LuxuryResaleBrandsTitle/>
      <LuxuryResaleBrands/>
      <LuxuryResaleBusinessOpportunity/>
      <LuxuryResaleDownloadScrollButton/>
      <LuxuryResaleText1/>
      <LuxuryResalePicture2/>
      <LuxuryResaleText2/>
      <LuxuryResalePicture3/>
      <LuxuryResalePicture4/>
      <LuxuryResaleHalfHalfTitle/>
      {/* <LuxuryResaleHalfHalf1/>
      <LuxuryResaleHalfHalf2/> */}
      <LuxuryResaleHalfHalf/>
      <LuxuryResaleNewsletter/>
      {/* <LuxuryResaleText2/>
      <LuxuryResalePicture3/>
      <LuxuryResaleText3/>
      <LuxuryResalePicture4/>
      <LuxuryResaleText4/>
      <LuxuryResalePicture5/>
      <LuxuryResaleText5/>
      <LuxuryResaleNewsletter /> */}
    </LayoutLuxuryResale>
  )
}


export default LuxuryResalePage