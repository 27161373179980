import React from "react";
import "../scss/LuxuryResaleHalfHalf.scss"; // Import your SCSS file
import { Helmet } from "react-helmet";

const LuxuryResaleHalfHalf = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet>
        <title>Luxury Resale Half Half</title>
        {/* Add any additional meta tags or SEO improvements here */}
      </Helmet>
      <div className="half-container">
        <div className="left-half">
          <div className="LuxuryResaleLeftTextContainer">
            <h1>Ready to Wear Report</h1>
            <div className="HalfSection1">
              <h3>A Complete Guide to Luxury Resale Customers</h3>
              <p><span className="LRBullet"><b>•</b></span> Top 3 reasons customers purchase pre-owned luxury</p>
              <p><span className="LRBullet"><b>•</b></span> Most popular cities where luxury resale customers live</p>
              <p><span className="LRBullet"><b>•</b></span> How to gain luxury resale customers</p>
            </div>

            <div className="HalfSection2">
              <h3>Market Insights - Go to Market Guide</h3>
              <p><span className="LRBullet"><b>•</b></span> Most popular luxury resale companies</p>
              <p><span className="LRBullet"><b>•</b></span> Most popular luxury resale products and categories</p>
              <p><span className="LRBullet"><b>•</b></span> Top 3 most popular regions for luxury resale</p>
              <p><span className="LRBullet"><b>•</b></span> How to enter the luxury resale market</p>
            </div>

            <div className="HalfSection3">
              <h3>Case Study - Luxury Brand Resale Success</h3>
              <p><span className="LRBullet"><b>•</b></span> How one luxury brand is making millions of dollars from luxury resale</p>
            </div>

            {/* <div className="HalfSection4">
              <h3>Insights from Luxury Resale Leaders</h3>
              <p><span className="LRBullet"><b>•</b></span> Interviews from luxury resales c-suite leaders</p>
            </div> */}
          </div>
          <div className="contentFeatureLuxuryResaleHalfHalfButton">
            <div className="LuxuryResaleHalfHalfButtonContainer">
              <button className="download-buttonHalfHalf" onClick={scrollToBottom}>
                Download Report
              </button>
            </div>
          </div>
        </div>
        <div className="right-half">
          <div className="LuxuryResaleRightTextContainer">
            <h1>Made to Measure Report</h1>
            <br />
            <h3>A Complete Guide to Luxury Resale Customers</h3>
            <p><span className="LRBullet"><b>•</b></span> Top 3 reasons customers purchase pre-owned luxury</p>
            <p><span className="LRBullet"><b>•</b></span> Most popular cities where luxury resale customers live</p>
            <p><span className="LRBullet"><b>•</b></span> How to gain luxury resale customers</p>
            <p><b><span className="LRBullet">•</span> Socio demographic analysis of luxury vip customer and sellers</b></p>
            <p><b><span className="LRBullet">•</span> How to gain luxury vip customers and sellers</b></p>
            <br />
            <h3>Market Insights - Go to Market Guide</h3>
            <p><span className="LRBullet"><b>•</b></span> Most popular luxury resale companies</p>
            <p><span className="LRBullet"><b>•</b></span> Most popular luxury resale products and categories</p>
            <p><span className="LRBullet"><b>•</b></span> Top 3 most popular regions for luxury resale</p>
            <p><span className="LRBullet"><b>•</b></span> How to enter the luxury resale market</p>
            <p><b><span className="LRBullet">•</span> Top reasons luxury vip customers purchase</b></p>
            <p><b><span className="LRBullet">•</span> How to gain luxury vip customers and sellers</b></p>
            <br />
            <h3>Case Study - Luxury Brand Resale Success</h3>
            <p><span className="LRBullet"><b>•</b></span> How one luxury brand is making millions of dollars from luxury resale</p>
            <br />
            <h3>Insights from Luxury Resale Leaders</h3>
            <p><span className="LRBullet"><b>•</b></span> Interviews from luxury resales c-suite leaders</p>
          </div>
          <div className="contentFeatureLuxuryResaleHalfHalfButton2">
            <div className="LuxuryResaleHalfHalfButtonContainer2">
              <a href="mailto:hey@thefreshkid.com">
                <button className="download-buttonHalfHalf2">
                  Make Enquiry (Price on Application)
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LuxuryResaleHalfHalf;
