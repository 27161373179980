import React, { useEffect } from "react";
import "../scss/LuxuryResaleText2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Helmet } from "react-helmet";

export default function LuxuryResaleText2() {
  const data = useStaticQuery(graphql`
  query MyQueryLRText1 {
    allContentfulTextBlock(
      filter: { id: { eq: "93857e88-a965-5c79-9bfe-9335bc4cb0f7" } }
    ) {
      edges {
        node {
          title
          bodyText {
            raw
          }
          id
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <>
      <Helmet>
        <h2>Insights and Go to Market Guide for Any Luxury Brand</h2>
      </Helmet>
    <div className="contentFeatureLuxuryResale2">
      <div className={'textContainerLuxuryResale2'}>
        <p className={'titleLuxuryResale2'}>{title}</p>
        <p className='bodyLuxuryResale2'>
          <p><span className="LRBullet"><b>•</b></span> Most popular luxury resale companies</p>
          <p><span className="LRBullet"><b>•</b></span> Most popular luxury resale products and categories</p>
          <p><span className="LRBullet"><b>•</b></span> Top 3 most popular regions for luxury resale</p>
          <p><b>MADE TO MEASURE (By Enquiry Only)</b></p>
          <p><span className="LRBullet"><b>•</b></span> Deep insights into leading luxury resale companies</p>
        </p>
      </div>
    </div>
    </>
  );
}
