import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import "../scss/HeroImageLuxuryResale.scss"
import { Helmet } from "react-helmet";

export default function HeroImageLuxuryResale() {
    const data = useStaticQuery(graphql`
    query MyQueryLRHero {
      allContentfulHeroImage(filter: { name: { eq: "Luxury Resale Report Hero Image" } }) {
        edges {
          node {
            heroImage {
              altText
              image {
                file {
                  url
                }
                id
              }
              name
            }
          }
        }
      }
    }
  `)

    return (
      <>
        {/* <Helmet>
        <h2>The Business Case for Luxury Resale – Rental & Plus Sizing</h2>
      </Helmet> */}
        <div className="heroImageLuxuryResaleContainer">
            <img
              className={"heroImageLuxuryResale"}
              alt={`Luxury circular economy business model`}
              key={``} 
              src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}

            />
        </div>
      </>
    )
}