import React, { useEffect } from "react";
import "../scss/LuxuryResaleTextIntro.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Helmet } from "react-helmet";

export default function LuxuryResaleTextIntro() {
  const data = useStaticQuery(graphql`
  query MyQueryLRTextIntro {
    allContentfulTextBlock(
      filter: { id: { eq: "67bd471a-206a-53ea-9cf0-40aefec6a145" } }
    ) {
      edges {
        node {
          title
          bodyText {
            raw
          }
          id
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <>
      <Helmet>
        <title>The Luxury Resale Report</title>
        <meta name="description" content="The Luxury Resale Report – Gain New Customers - Enhance Brand - Diversify Revenue" />
        <meta name="keywords" content="Luxury Circular Economy, Luxury Circular Economy benefits, luxury circular business model" />
        <h1>Luxury Resale Million Dollar Growth Opportunity</h1>
        <h2>Diversify Revenue, Enhance brand and Gain new customers</h2>
      </Helmet>
    <div className="contentFeatureLuxuryResaleIntro">
      <div className={'textContainerLuxuryResaleIntro'}>
        <p className={'titleLuxuryResaleIntro'}>{title}</p>
        <p className='bodyLuxuryResaleIntro'>
          {documentToReactComponents(
            JSON.parse(bodyText.raw),
            options
          )}
        </p>
      </div>
    </div>
    </>
  );
}
