import React from "react";
import "../scss/LuxuryResalePicture4.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";

export default function LuxuryResalePicture4() {
  const data = useStaticQuery(graphql`
    query MyQueryLRPic4 {
      allContentfulImageBlock(filter: { name: { eq: "Luxury Resale Video 2" } }) {
        edges {
          node {
            image {
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  return (
    <div className="contentFeatureLuxuryResalePic4">
      <img
        className="imageContainerLuxuryResalePic4"
        src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
        alt="Luxury circular economy benefits"
      />
    </div>
  );
}
