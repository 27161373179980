import React, { useEffect, useState } from "react"
import "../scss/LuxuryResalePicture1.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";

export default function LuxuryResalePicture1() {
    const data = useStaticQuery(graphql`
    query MyQueryLRPic1 {
      allContentfulImageBlock(filter: { name: { eq: "Luxury Resale Video" } }) {
        edges {
          node {
            image {
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `)

  return (
    <div className="contentFeatureLuxuryResalePic1">
        <video
            className={"imageContainerLuxuryResalePic1"}
            src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
            alt="Luxury circular economy benefits"
            autoPlay
            loop
            muted
        >
            Your browser does not support the video tag.
        </video>
    </div>
);
}