import React, { useEffect, useState } from "react"
import "../scss/LuxuryResalePicture2.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image"

export default function LuxuryResalePicture2() {
  const data = useStaticQuery(graphql`
    query MyQueryLRPic2 {
      allContentfulImageBlock(filter: { name: { eq: "Luxury Resale Report Image 2" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureLuxuryResalePic2">
      <div className={"imageContainerLuxuryResalePic2"}>
        <StaticImage data-aos="fade-up" className={"bigImage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/2kIp3pvHIWIiasYqpSJtjr/43490aba6f9d6463a101b054f5dc3df1/lrrp.png" layout="fullWidth" alt="Preowned luxury customer"/>
      </div>
    </div>
  );
}
