import React, { useEffect, useState } from "react"
import "../scss/LuxuryResalePicture3.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image"

export default function LuxuryResalePicture2() {
  const data = useStaticQuery(graphql`
    query MyQueryLRPic3 {
      allContentfulImageBlock(filter: { name: { eq: "Luxury Resale Report Image 3" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureLuxuryResalePic3">
      <div className={"imageContainerLuxuryResalePic3"}>
        <StaticImage data-aos="fade-up" className={"bigImage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/7D8VTzrNlgYTbPDQ84921m/5299943539d5347fb82e215dfca45e72/luxury_resale_page_image_3.png" layout="fullWidth" alt="Luxury resale market"/>
      </div>
    </div>
  );
}
