import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import "../scss/LuxuryResaleBusinessOpportunity.scss";
import { Helmet } from "react-helmet";

const LuxuryResaleBusinessOpportunity = () => {
  const data = useStaticQuery(graphql`
    query LuxuryResaleBusinessOpportunity {
      allContentfulValuePropositionBar(filter: { id: { eq: "7e4789bd-db45-5ee2-9ad6-d260ff2df964" } }) {
        edges {
          node {
            bodyText
            bodyText2
            bodyText3
            title
            title2
            title3
            image3 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image2 {
              altText
              image {
                file {
                  url
                }
              }
            }
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
            outsideTitle
          }
        }
      }
    }
  `);

  const {
    title,
    title2,
    title3,
    bodyText,
    bodyText2,
    bodyText3,
    image,
    image2,
    image3,
  } = data.allContentfulValuePropositionBar.edges[0].node;
  // const [name3LuxuryResale, description3LuxuryResale] = title3.split("Customers");

  return (
    <>
      <Helmet>
        <h2>The Business Opportunity</h2>
      </Helmet>
    <div className="contentFeatureWhatIDoLuxuryResale">
      <div className="wbarLuxuryResale">
        <div className="outsideTitleLuxuryResale">The Business Opportunity</div>
        <div className="witemsLuxuryResale">
          <div className="witem1LuxuryResale">
            <div className="wtitle1LuxuryResale"><strong>{title}</strong></div>
            <img className="wimage1LuxuryResale" src={image.image.file.url} alt={image.altText} />
            <div className="wsentence1LuxuryResale">{bodyText}</div>
          </div>
          <div className="witem2LuxuryResale">
            <div className="wtitle2LuxuryResale"><strong>{title2}</strong></div>
            <img className="wimage2LuxuryResale" src={image2.image.file.url} alt={image2.altText} />
            <div className="wsentence2LuxuryResale">{bodyText2}</div>
          </div>
          <div className="witem3LuxuryResale">
            <div className="wtitle3LuxuryResale"><strong>{title3}</strong></div>
            <img className="wimage3LuxuryResale" src={image3.image.file.url} alt={image3.altText} />
            <div className="wsentence3LuxuryResale">{bodyText3}</div>
          </div>
          {/* <div className="witem3LuxuryResale">
            <div className="wtitle3LuxuryResale"><strong>
              {name3LuxuryResale}
              <span className="highlight3About">Customers</span><br />
              {description3LuxuryResale}
            </strong></div>
            <img className="wimage3LuxuryResale" src={image3.image.file.url} alt={image3.altText} />
            <div className="wsentence3LuxuryResale">{bodyText3}</div>
          </div> */}
        </div>
      </div>
    </div>
    </>
  );
};

export default LuxuryResaleBusinessOpportunity;
