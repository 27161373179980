import React, { useEffect, useState } from "react";
import "../scss/LuxuryResaleNewsletter.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";

export default function LuxuryResaleNewsletter() {
    const data = useStaticQuery(graphql`
    query LuxuryResaleNewsletter {
      allContentfulNewsletterBar (filter:{callToAction:{eq:"Download the Report"}}){
        edges {
          node {
            callToAction
            title
            subTitle
            otherInfo2
            termsAndConditionsText {
              raw
            }
            emailPlaceholder {
              microcopyText
            }
          }
        }
      }
    }
    `)

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [email, setEmail] = useState("");
  const [canDownload, setCanDownload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setCanDownload(e.target.value !== "");

    if (errorMessage) {
      setErrorMessage('');
    }
  };


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!email || !email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/)) {
  //     setErrorMessage('Please Enter a valid email address');
  //   } else {
  //     setErrorMessage("");
  //     setIsSubmitting(true);
  //     e.target.submit();
  //     setTimeout(() => {
  //       downloadReport();
  //       setIsSubmitting(false);
  //     }, 1000);
  //   }
  // };


  const desiredNode = data.allContentfulNewsletterBar.edges[0].node;
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [isEmailInputEnabled, setIsEmailInputEnabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('MERGE0');
    console.log("Selected Radio Button Value: ", selectedRadioValue);
    console.log("Email: ", email);
  
    const emailInput = document.querySelector('input[type="email"]');
    const enteredEmail = emailInput.value.trim().toLowerCase();
  
    const disallowedDomains = ['@gmail', '@yahoo', '@icloud', '@aol', '@mac', '@hotmail', '@outlook', '@proton', '@protonmail', '@zohocorp', '@gmx', '@yandex', '@hubspot', '@neo', '@thunder', '@degcos', '@me'];
    const disallowedPrefixes = ['info', 'contact']; // Disallowed prefixes
  
    const containsDisallowedDomain = disallowedDomains.some(domain => enteredEmail.includes(domain));
    const emailPrefix = enteredEmail.split('@')[0]; // Extract the part before '@'
    const containsDisallowedPrefix = disallowedPrefixes.some(prefix => emailPrefix === prefix);
  
    if (containsDisallowedDomain) {
      setErrorMessage('Please use your business email');
      console.error('Disallowed email domain used:', enteredEmail);
      return;
    } else if (containsDisallowedPrefix) {
      setErrorMessage('Please use your business email');
      console.error('Disallowed email prefix used:', enteredEmail);
      return;
    }
  
    // If validation passes, proceed with form submission
    setErrorMessage('');
    console.log('Form submitted successfully!');
    e.target.submit(); // Manually trigger form submission
  
    // downloadReport();
  };
  


  const handleRadioChange = (e) => {
    setSelectedRadioValue(e.target.value);
    setIsEmailInputEnabled(true);
  }

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  const downloadReport = () => {
    const url =
      "https://assets.ctfassets.net/x5jqyuhgi4rx/5boHG6LLHuDAcAXjVUYufm/a359af3334b2ac8d3d89590ed4bbf31c/Luxury_Resale_Report.pdf";
    const link = document.createElement("a");
    link.href = url;
    link.download = "The_Fashion_Tech_Report.pdf";
    link.click();
  };
  

  const { otherInfo2} = data.allContentfulNewsletterBar.edges[0].node;
  const [before, after] = otherInfo2.split("anyone else.");

  return (
    <>
      <Helmet>
        <h2>Stay Ahead of The Market</h2>
      </Helmet>
    <div className="contentFeatureLuxuryResaleNewsletter">
      <div className="backgroundSquareNewsletter"></div>
      <div className={'textContainerLuxuryResaleNewsletter'}>
        <p className={'titleLuxuryResaleNewsletter'}>Stay Ahead of&nbsp;&nbsp;The Market</p>
        {/* <p className={'subBodyLuxuryResaleNewsletter'}>{data.allContentfulNewsletterBar.edges[0].node.subTitle}</p> */}
      </div>
      <div class="signUpSectionLuxuryResaleNewsletter">
        <form
          onSubmit={handleSubmit}
          action="https://thefreshkid.us8.list-manage.com/subscribe/post"
          method="POST"
          target="_blank"
        >
          <input
            type="hidden"
            name="u"
            value="8abd2b2ed117ea16fd4a66f6d"
          />
          <input
            type="hidden"
            name="id"
            value="ba31c59c12"
          />

          <div className="radioSelectionLuxuryResale">
            <label className="radioButtonsLuxuryResale1">
              <span className="radioInputLuxuryResale">
                <input type="radio" id="man" name="MMERGE4" value="Luxury Investor" onChange={handleRadioChange} />
                <span className="radioControlLuxuryResale"></span>
              </span>
              <span className="radioLabelLuxuryResale">Luxury Investor</span>
            </label>
            <label className="radioButtonsLuxuryResale2">
              <span className="radioInputLuxuryResale">
                <input type="radio" id="woman" name="MMERGE4" value="Luxury Leader" onChange={handleRadioChange} />
                <span className="radioControlLuxuryResale"></span>
              </span>
              <span className="radioLabelLuxuryResale">Luxury Leader</span>
            </label>
            <label className="radioButtonsLuxuryResale3">
              <span className="radioInputLuxuryResale">
                <input type="radio" id="otheroo" name="MMERGE4" value="Luxury Student" onChange={handleRadioChange} />
                <span className="radioControlLuxuryResale"></span>
              </span>
              <span className="radioLabelLuxuryResale">Luxury Student</span>
            </label>
            <label className="radioButtonsLuxuryResale3">
              <span className="radioInputLuxuryResale">
                <input type="radio" id="othero" name="MMERGE4" value="Other" onChange={handleRadioChange} />
                <span className="radioControlLuxuryResale"></span>
              </span>
              <span className="radioLabelLuxuryResale">Other</span>
            </label>
          </div>
          
          <input
            type="email"
            name="MERGE0"
            id="emailInput"
            class="emailInputLuxuryResaleNewsletter"
            placeholder={data.allContentfulNewsletterBar.edges[0].node.emailPlaceholder.microcopyText}
            onChange={handleEmailChange}
            required
          />
          {/* {errorMessage && (
            <p className="errorMessage">{errorMessage}</p>
          )} */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}





          <div>
            <input
              type="submit"
              class="callToActionLuxuryResaleNewsletter"
              value={data.allContentfulNewsletterBar.edges[0].node.callToAction}
              disabled={!canDownload || isSubmitting}
            />
          </div>

          <p className="termsLuxuryResale">
          By signing up, you agree to our <u>Privacy & Cookies Policy</u>
        </p>
        <div className="termsLuxuryResaleo">
              {before}
              <span className="highlight3Newsletter">anyone else.</span><br />
              {after}
        </div>
          
        </form>
      </div>

    </div>
    </>
  );
}

