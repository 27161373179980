import React, { useEffect } from "react";
import "../scss/LuxuryResaleBrandsTitle.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Helmet } from "react-helmet";

export default function LuxuryResaleBrandsTitle() {
  const data = useStaticQuery(graphql`
  query MyQueryLRBrandsTitle {
    allContentfulTextBlock(
      filter: { id: { eq: "48ff2d3a-85a1-5d2f-bfcb-197e36dd4e3c" } }
    ) {
      edges {
        node {
          title
          bodyText {
            raw
          }
          id
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <>
      <Helmet>
        <h2>Luxury Resale Driving Growth at Luxury's Leading Brands</h2>
      </Helmet>
    <div className="contentFeatureLuxuryResaleBrandsTitle">
      <div className={'textContainerLuxuryResaleBrandsTitle'}>
        <p className={'titleLuxuryResaleBrandsTitle'}>Luxury Resale Driving Growth at Luxury's Leading Brands</p>
      </div>
    </div>
    </>
  );
}
