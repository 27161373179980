import React from "react";
import "../scss/LuxuryResaleDownloadScrollButton.scss"

export default function LuxuryResaleDownloadScrollButton() {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    
    <div className="contentFeatureLuxuryResaleButton">
    <div className={"LuxuryResaleButtonContainer"}>
      <button className="download-button" onClick={scrollToBottom}>
          Download Report
      </button>
    </div>
  </div>
  );
}
